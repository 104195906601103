<template>
  <div class="login">
    <img class="logo" src="login.png" alt="">
    <div  class="login-body login">
      <van-form @submit="onSubmit">
        <van-field
          v-model="input_phonenum"
          name="phone"
          label="手机号:"
          placeholder="输入手机号"
          size=large
          :rules="[{ required: true, message: '请输入手机号' }]"
        />
        <van-field
          v-model="input_password"
          type="password"
          name="password"
          size=large
          label="密码："
          placeholder="密码"
          :rules="[{ required: true, message: '请填写密码' }]"
        />
        <div style="margin: 16px;">
          <van-button round block color="#1baeae" native-type="submit">登录</van-button>
        </div>
        <div style="margin: 16px;">
          <van-button round block  @click="onHandleRouter('/resetpassword')">忘记密码</van-button>
        </div>
        <div class="login-bottom" v-show="divShow">
            <a href="https://beian.miit.gov.cn/" target="_blank">冀ICP备2022025588号-2</a>
        </div>
      </van-form>
    </div>
  </div>
</template>
<script>
import { ref,onMounted} from 'vue';
import { useUserStore } from '@/store/user.ts'
import {login} from '@/service/user'
import { useRouter } from 'vue-router'
import MonitorKeyboard from '@/utils/monitorKeyboard.js'
export default {
  setup() {
    const input_phonenum=ref('')
    const input_password=ref('')
    const input_verifycode=ref('')

    const isexist_password=ref(true)
    const userStore = useUserStore()
    const router = useRouter()
    
    const divShow = ref(true)
    let monitorKeyboard = null

    //登录系统
    const onSubmit=(values)=>{
      let name=values.phone;
      let password=values.password;
      let site_vip='';
      let str_site_vip=window.location.href.split("/")[2].split(".")
      for (let i=0;i<str_site_vip.length-2;i++){
        site_vip=site_vip+str_site_vip[i]
      }
      //site_vip='fzyakfzysmltd'
      //login(name, password,site_vip).then((result)=>{
      login(name, password).then((result)=>{
         if(result.code >= 0){
           let data={
              id:result.data.id,
              name:result.data.name,
              phone:result.data.phone,
              level:result.data.level,
              token:result.data.token,
              expiretime:result.data.expiretime
           };
           userStore.setUserInfo(data)
           router.push('/home');
         }
      })
    };
    //重置密码
    const resetpassword=(values)=>{
      router.push('/resetpassword');
    }

    const getKeyboardState = () => {
      monitorKeyboard = new MonitorKeyboard();
      monitorKeyboard.onStart();

      // 监听虚拟键盘弹出事件
      monitorKeyboard.onShow(() => {
        divShow.value = false;
      })

      //监听键盘收起的事件
      monitorKeyboard.onHidden(() => {
        divShow.value = true;
      })
    };
    onMounted(() => {
      getKeyboardState();
    });

    return{
      input_phonenum,
      input_password,
      input_verifycode,
      userStore,
      router,
      isexist_password,
      onSubmit,
      resetpassword,
      getKeyboardState,
      divShow,
      monitorKeyboard
    };
  }
}
</script>

<style scoped lang="less">
.login {
    .logo {
      width: 120px;
      height: 120px;
      display: block;
      margin: 80px auto 20px;
    }
    .login-body {
      border-radius: 10px;
      padding: 0 20px;
    }
    .login {
      .link-register {
        font-size: 14px;
        margin-bottom: 20px;
        color: #1989fa;
        display: inline-block;
      }
    }
    .register {
      .link-login {
        font-size: 14px;
        margin-bottom: 20px;
        color: #1989fa;
        display: inline-block;
      }
    }
    .verify-bar-area {
      margin-top: 24px;
      .verify-left-bar {
        border-color: #1baeae;
      }
      .verify-move-block {
        background-color: #1baeae;
        color: #fff;
      }
    }
    .verify {
      >div {
        width: 100%;
      }
      display: flex;
      justify-content: center;
      .cerify-code-panel {
        margin-top: 16px;
      }
      .verify-code {
        width: 40%!important;
        float: left!important;
      }
      .verify-code-area {
        float: left!important;
        width: 54%!important;
        margin-left: 14px!important;
        .varify-input-code {
          width: 90px;
          height: 38px!important;
          border: 1px solid #e9e9e9;
          padding-left: 10px;
          font-size: 16px;
        }
        .verify-change-area {
          line-height: 44px;
        }
      }
    }
    .login-bottom{
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    a{
      color: #495770;
      text-decoration: none;
      font-size: 8px;
    }
    a:hover {
      color: black;
      font-weight: bold;
    }
  }


</style>




